'use client'

import {useState} from 'react'
import Image from 'next/image'
import Section from './Section'
import {TbCardsFilled} from 'react-icons/tb'
import {FaStar} from 'react-icons/fa'
import {FaGlobeAmericas} from 'react-icons/fa'
import {BsFillMegaphoneFill} from 'react-icons/bs'
import {FaHeart} from 'react-icons/fa'
import {RiLockStarFill} from 'react-icons/ri'
import {FaArrowCircleLeft} from 'react-icons/fa'
import {FaArrowCircleRight} from 'react-icons/fa'
import {useVisibility} from '@helpers/scrollHelper'

const items = [
	{
		id: 1,
		title: 'Impact Highlights',
		subtitle: 'Customisable summary of card details, brand logo, image and impact outcomes, designed as an engagement point for consumers.',
		imageName: 's1.png',
		iconSrc: <TbCardsFilled className="tw-h-7 tw-w-7" />,
	},
	{
		id: 2,
		title: 'Rewards',
		subtitle: 'Run competitions, add Promo codes or display URLs to drive customer engagement.',
		imageName: 's2.png',
		iconSrc: <FaStar className="tw-h-7 tw-w-7" />,
	},
	{
		id: 3,
		title: 'Impact Project',
		subtitle: 'Organisations that receive funding from Everclime to deliver Impact outcomes.',
		imageName: 's3.png',
		iconSrc: <FaGlobeAmericas className="tw-h-7 tw-w-7" />,
	},
	{
		id: 4,
		title: 'Brand Collection',
		subtitle: 'Brands can visualise and track their impact build their impact legacy',
		imageName: 's4.png',
		iconSrc: <BsFillMegaphoneFill className="tw-h-7 tw-w-7" />,
	},
	{
		id: 5,
		title: 'Brand',
		subtitle: 'Organisation that is investing and supporting Impact Project outcomes',
		imageName: 's5.png',
		iconSrc: <FaHeart className="tw-h-7 tw-w-7" />,
	},
	{
		id: 6,
		title: 'Authenticity',
		subtitle: "Digital registries tracking the impact outcomes against the brands via serial numbers and registry ID's",
		imageName: 's6.png',
		iconSrc: <RiLockStarFill className="tw-h-7 tw-w-7" />,
	},
]

export default function Explore() {
	const [activeId, setActiveId] = useState(1)
	const [isSliding, setIsSliding] = useState(false)

	const [isVisible, scrollElement, scrollPosition] = useVisibility<HTMLDivElement>()

	const visibleItem = () => items.find((item) => item.id === activeId) || items[0]

	const handleImageChange = (id: number) => {
		setActiveId(id)
		setIsSliding(true)
		setTimeout(() => {
			setIsSliding(false)
		}, 500)
	}

	const decrementId = () => {
		const newId = activeId === 1 ? items.length : activeId - 1
		handleImageChange(newId)
	}

	const incrementId = () => {
		const newId = activeId === items.length ? 1 : activeId + 1
		handleImageChange(newId)
	}

	const handleClick = (id: number) => handleImageChange(id)

	const calculateTranslate = (start: number, end: number, offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		return Math.min(1, scrollPosition / elementEndPosition)
	}

	return (
		<div className="section-container">
			<div className="sub-container" ref={scrollElement}>
				<h2 className="tw-mb-2">
					What are <span className="tw-text-primary">Impact Cards</span>?
				</h2>
				<p className="tw-mb-4 tw-text-base lg:tw-text-xl">A digital receipt connecting partners, rewards and impact outcomes in one place</p>
				<div className="tw-grid tw-w-full tw-grid-cols-1 tw-gap-4 tw-overflow-hidden tw-rounded-xl lg:tw-grid-cols-5 lg:tw-grid-rows-3 lg:tw-flex-row lg:tw-justify-between lg:tw-gap-4">
					<Section
						className={'tw-hidden md:tw-flex'}
						isActive={activeId === 1}
						{...items[0]}
						onClick={() => handleClick(1)}
						style={{
							transform: `translateX(${calculateTranslate(1 * -200, 0)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					/>
					<div
						className="tw-flex tw-flex-col tw-justify-center tw-overflow-hidden tw-rounded-lg tw-bg-primary/60 tw-bg-blend-overlay tw-shadow-sm md:tw-col-span-3 md:tw-row-span-3"
						style={{
							backgroundImage: "url('/assets/images/landingpage/explore/blurry-bg.png')",
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center',
							transform: `translateY(${calculateTranslate(200, 0, 300)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						<div className="tw-flex tw-justify-center tw-py-10">
							<div
								className={`tw-relative tw-aspect-[1.25/2] tw-h-[200px] tw-animate-[hoverEffect_4s_ease-in-out_infinite] tw-duration-[800ms] lg:tw-h-[400px] ${
									isSliding ? 'tw-animate-[slide-in-right.5s_ease_forwards]' : ''
								}`}
							>
								<Image src={`/assets/images/landingpage/explore/card-previews/${visibleItem().imageName}`} layout="fill" objectFit="contain" alt="Picture of the card step" />
							</div>
						</div>
						<div className="tw-flex tw-flex-col tw-bg-white tw-p-10 lg:tw-flex-row">
							<div>
								<div className="tw-flex">
									<div className={`mb-3 tw-mr-2 tw-text-primary tw-duration-[800ms]`}>{visibleItem().iconSrc}</div>
									<p className="tw-text-lg tw-font-bold">{visibleItem().title}</p>
								</div>

								<div style={{minHeight: '64px'}}>
									<span>{visibleItem().subtitle}</span>
								</div>
							</div>
							<div className="tw-mt-4 tw-flex tw-flex-1 tw-flex-row tw-items-end tw-justify-between tw-gap-2 lg:tw-mt-0 lg:tw-flex-col lg:tw-justify-end">
								<p>
									{activeId}/{items.length}
								</p>
								<div className="tw-flex tw-gap-2">
									<FaArrowCircleLeft className="tw-h-7 tw-w-7 tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary" onClick={decrementId} />
									<FaArrowCircleRight className="tw-h-7 tw-w-7 tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary" onClick={incrementId} />
								</div>
							</div>
						</div>
					</div>
					{items.slice(1).map((item) => (
						<Section
							key={item.id}
							className={'tw-hidden md:tw-flex'}
							isActive={activeId === item.id}
							{...item}
							onClick={() => handleClick(item.id)}
							style={{
								transform: `translateX(${calculateTranslate((item.id % 2 ? 1 : -1) * (item.id * 0.9) * -200, 0)}px)`,
								opacity: `${calculateOpacity()}`,
								transition: 'transform opacity 0.1s',
							}}

							// style={{
							//   transform: `translateX(${item.id % 2 ? Math.max(200 - scrollPosition - scrollElement?.current?.offsetTop + item.id * 20, 0) : Math.min(-200 + scrollPosition - scrollElement?.current?.offsetTop * item.id * 20, 0)}px)`,
							//   opacity: `${Math.min(scrollPosition / 100, 1)}`,
							//   transition: "transform opacity 0.1s",
							// }}
							// style={{
							//   transform: `translateX(${(index + 1) % 2 ? Math.max(200 - scrollPosition + (index + 1) * 20, 0) : Math.min(-200 + scrollPosition + (index + 1) * 20, 0)}px)  `,
							//   opacity: `${Math.min(scrollPosition / 200, 1)}`,
							//   transition: "transform opacity 0.1s",
							// }}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

// 200 - -300 + 20 = 520, 0 => 0
// sp: 1200
// se: 1500
// 1*20 = 20
