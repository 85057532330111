'use client'

import {ReactElement} from 'react'

interface Props {
	id: number
	iconSrc: ReactElement
	title: string
	subtitle: string
	className: string
	isActive: boolean
	onClick: (id: number) => void
	style: object
}

export default function Section(props: Props) {
	return (
		<div
			className={`tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-sm tw-duration-300 hover:tw-shadow-md ${props.className}`}
			onClick={() => props.onClick(props.id)}
			style={props.style}
		>
			<div className={`tw-mb-3 tw-text-gray-400 tw-duration-[800ms] ${props.isActive ? '!tw-text-primary' : ''}`}>{props.iconSrc}</div>
			<div className="tw-flex tw-flex-col">
				<h5 className="!tw-mt-0 !tw-py-0 tw-text-xl lg:tw-text-xl">{props.title}</h5>
			</div>
		</div>
	)
}
